import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default ({data}) => (
  <Layout>
    <SEO title="Nedostatek a nadbytek živin v přírodě" />
    <h1>Nedostatek a nadbytek živin v přírodě</h1>
    <p><strong>Živiny</strong> jsou stavební prvky pro rostliny, z&nbsp;hlediska zelených rostlin jde především o množství makroprvků v&nbsp;půdě (dusík, fosfor, draslík – ty se využívají v&nbsp;tzv. NPK hnojivích). Mikroprvky, mezi něž patří například hořčík a vápník, jsou též důležité, ale stačí rostlinám v&nbsp;menším množství.</p>
    <p>V&nbsp;lesích pro dostatečné množství živin přirozeně stačí zvětrávání minerálů, pole ale obvykle silně dohnojujeme. Neplatí, že čím více živin, tím lépe. Při příliš velkém množství živin v&nbsp;půdě rostlina uschne, protože je zní přes osmotický proces vysána vlhkost a sama rostlina žádnou nemůže přijmout.</p>
    <hr />
    <h2>Ranná historie</h2>
    <p>Obecně lze tvrdit, že do roku 1950 trpí naše krajina nedostatkem živin a po roce 1950 naopak nadbytkem. Lidstvo po 7000 let bojovalo s&nbsp;jejich nedostatkem.</p>
    <p>Zemědělství již v&nbsp;neolitu vyžadovalo množství živin, tehdy byly vypalovány lesy. Z&nbsp;popela se uvolnily živiny a na této ploše zhruba 15 let pěstovali plodiny, poté se půda vyčerpala, byla napadena houbami atd. Tato metoda měla obrovské nároky na prostor.</p>
    <p>Množství živin limitovalo i počty dobytka, který sice nějaké živiny vracel do půdy (úhory a úhorové hospodaření), ale na zimu musela být většina stáda vybita.</p>
    <p>Zdroje živin se hledali všude, i v&nbsp;lesích. Zde lidé nechali pást dobytek a asi v&nbsp;době železné si všímají, že ožírá zelené výhonky a malé větve stromů. Tyto pak byli lidmi osekávány a přidávány do sena.</p>
    <p>Malé množství živin obecně omezovalo produkci na polích i dobytka, tím byla limitována i velikost lidské populace.</p>
    <hr />
    <h2>Nitrofilní a nitrofobní rostliny</h2>
    <p>Z&nbsp;hlediska požadavků na množství dusíku lze rozlišit rostliny na dusík náročné (nitrofilní či nitrofyty) a nesnášející nadbytek dusíku (nitrofobní).</p>
    <p><strong>Nitrofilní</strong> druhy byly dříve relativně vzácné, vyskytovaly se především v&nbsp;nivách řek (proto byly první civilizace v&nbsp;nivách – dostatek živin).</p>
    <p>Příkladem nitrofilní rostliny je kopřiva, která byla v&nbsp;minulosti vzácnou rostlinou a vyskytovala se nejspíše jen v nížinách na úpatí podmáčených sutí. Dnes díky většímu počtu dusíku v&nbsp;krajině je téměř všude a nedělá ji problém ani nadmořská výška.</p>
    <p><strong>Nitrofobní</strong> rostliny nesnáší nadbytek dusíku hlavně proto, že při větším počtu živin přichází konkurence, která je vytlačí. Lidstvo nemohlo využívat nitrofobní druhy, protože jde o S-stratégy, kteří nesnáší narušování biomasy, jsou malí a neprodukční.</p>
    <hr />
    <h2>Pokračování historického vývoje živin v&nbsp;krajině</h2>
    <p>Vlivem agrotechniky se postupně zvyšuje produkce a tím i populace, nicméně živin je v&nbsp;krajině stále málo.</p>
    <p>Začínají se zvolna dovážet minerální sedimenty ze suchých tropů – jde především o čilský ledek. Dovoz je však velmi drahý a rolníci si jej jen výjimečně mohou dovolit, hledají se proto nové cesty.</p>
    <p>Jednou z&nbsp;cest je těžení <strong>fosfátových hlín</strong>, které se u nás využívají ještě za 1. republiky. Šlo o jeskynní hlíny s&nbsp;příměsí guána, například v&nbsp;Moravském krasu v&nbsp;jeskyni Výpustek bylo odtěženo přes 7 metrů těchto hlín, v&nbsp;Jáchymce pak až 13 metrů. Tato těžba mimo jiné zničila mnohé archeologické nálezy.</p>
    <p>V&nbsp;chemickém průmyslu dochází k&nbsp;syntéze hnojiv, první hnojiva ale silně okyselují půdu. Tento problém s&nbsp;okyselováním se řeší před 2. světovou válkou. Po válce pak tato syntetická hnojiva u nás zažívají boom, jsou relativně levná a dostupná. Zpočátku si lidé běžně přehnojovali těmito syntetiky zahrádky.</p>
    <p>Za komunistického režimu je vyvíjen tlak na zprůmyslnění zemědělství a zvýšení produkce. Obzvlášť po srpnové invazi dochází k&nbsp;budování velkokapacitních kravínů, vepřínů a intenzivnějšímu hnojení polí (podle hesla „dát lidem nažrat“ aby nedocházelo k nepokojům).</p>
    <p>Průmyslová hnojiva jsou dokonce dotovaná. Některé vesnice přichází o zdroje pitné vody, protože jsou otráveny nadbytkem živin, a jsou nuceny stavět dálkové vodovody.</p>
    <hr />
    <h2>Vývoj po revoluci</h2>
    <p>Po revoluci dochází k&nbsp;zastavení dotování hnojiv, končí i podpora Kuby a Afriky a tím se projevuje nadbytek potravin. Zemědělská a živočišná výroba zvolna upadá a s&nbsp;tím přestává i nadužívání NPK hnojiv.</p>
    <p>Zvrat přichází se vstupem do EU, která kvůli Francii hojně dotovala zemědělskou produkci. Ve Francii totiž v&nbsp;minulosti došlo k&nbsp;velkému vysidlování venkova a opouštění kulturní krajiny. Aby stát zvrátil tento trend, zavedl v&nbsp;70. letech národní dotace. Ty byly poté prosazeny i do EU.</p>
    <p>Zemědělci v&nbsp;ČR tak opět získávají dotace, čím více vyprodukují, tím více jich dostávají. Proto opět začíná intenzivnější hnojení v&nbsp;české krajině, ne však v&nbsp;takové míře, jako za socialismu. Rovněž se používají méně nebezpečná hnojiva, herbicidy a jiné látky.</p>
    <p>Dalším problém se ukázaly být prací a mycí prostředky na bázi fosforu, které nastoupily po revoluci. Až se vstupem do EU přišel zákaz fosfátových pracích prášků, po dalších dvou letech teprve zákaz prodeje. Analogicky přišel s&nbsp;2letým zpožděním zákaz fosfátových mycích prostředků.</p>
    <p>V&nbsp;době kolem revoluce začínají být odkanalizovány vesnice, do těchto kanálů pak vedou přepady ze septiků. Rovněž lidé přestávají vyvážet septik na pole. Splašky pak vedou do vodních toků, což vede k&nbsp;zhoršení kvality vod. V&nbsp;některých místech se vlivem tohoto rozšířily sinice a v&nbsp;takových oblastech byl zničen cestovní ruch (příklad Brněnské přehrady).</p>
    <p>S&nbsp;rozšířením čistíren odpadních vod dochází ke zlepšení situace, odebírají množství živin z&nbsp;vody. Jejich podstatná část pak zůstává v&nbsp;tzv.&nbsp;kalu, který je ale kvůli časté přítomnosti těžkých kovů těžko využitelný a je s&nbsp;ním tak běžně nakládáno jako s&nbsp;nebezpečným odpadem. Stále však příliš živin zůstává i ve vyčištěné vodě.</p>
    <p>Relativně nedávno začal být zaváděna technologie tzv. třetího stupně čištění vod, která odebírá z&nbsp;vody živiny, především dusík. Odebrání dusíku z&nbsp;vod však vedlo k&nbsp;nečekané situaci. Na něm jsou totiž závislé především řasy ve vodě, vlivem poklesu dusíku začal jejich počet klesat a uvolnily tak místo sinicím – cyanobakteriím. Ty jsou závislé především na fosforu, kterého ve vodě zůstalo dostatečné množství a úbytkem řas byly zbaveny konkurence.</p>
    <p>To vedlo například na Brněnské přehradě ke kuriózní situaci, kdy byl v&nbsp;roce 2010 na Veverské Bítýšce přidáván do vody dusík. Mimo to zde ale bylo provedeno množství opatření, včetně svedení kuřimské kanalizace do brněnské (zrušení ČOV v&nbsp;Kuřimi).</p>
    <hr />
    <h2>Další dopady nadbytku živin</h2>
    <p>V&nbsp;současnosti se dostává do české krajiny taktéž nadbytek dusíku z&nbsp;imisí dopravy (oxidy dusíku – NOx), což již může hraničit s&nbsp;ekologickou katastrofou.</p>
    <p>Krom rychlého růstu a vyšší zranitelnosti stromů, dochází i k&nbsp;posunu lesnické typologie, která vychází z&nbsp;dob, kdy bylo v&nbsp;lesích méně živin. Dříve neživná stanoviště jsou dnes až tzv. doživná, což působí v&nbsp;lesnické typologii zmatek. Typologie prodělala po 10 letech aktualizaci, nicméně stromy jsou dle ní vysazeny na 100 let – to je problém, poněvadž typologie by měla být trvalejší. Takto může dojít k&nbsp;potenciální krizi, kdy začne odumírat množství lesních kultur.</p>
    <p>Dochází taktéž již k&nbsp;výše zmíněné <strong>eutrofizaci vod</strong>, tedy k&nbsp;obohacování vod živinami. To souvisí s&nbsp;šířením řas (dusík) a sinic (tj. cyanobakterie; fosfor). Sinice negativně ovlivňují vodní společenstva, blokují přísun slunečního záření. Navíc byly dříve velmi vzácné a není tak na ně adaptace v&nbsp;naší krajině. V&nbsp;poslední době se šíří např. i v&nbsp;Baltském moři, příkladem je Finský záliv, kde je v&nbsp;blízkosti Petrohrad a je zde i malá salinita. V&nbsp;souvislosti s&nbsp;kůrovcovou kalamitou se pak do Plešného jezera uvolnilo velké množství dusíku, což zapříčinilo přemnožení řas a zakalení jinak průzračného jezera.</p>
    <p>Projevuje se taktéž <strong>nitrifikace horské krajiny</strong>, příkladem může být rozšíření nitrofilních pampelišek na vrcholcích Krkonoš či v&nbsp;Tatrách, kde dochází k&nbsp;obohacení dusíkem vlivem turismu (drobky od jídla, močení atd.).</p>
    <p>Dalším příkladem je rozšíření šťovíku alpského (rumex alpinus) v&nbsp;Alpách. Jde o 1,8 m vysokou, ruderální a nitrofilní rostlinu, kterou ani dobytek nežere. Likviduje vydatně konkurenci, až zůstane na stanovišti v&nbsp;podstatě sám – stává se z&nbsp;toho tzv. „horské pleveliště“, které je i fytocenologicky vymezeno. Obvykle se objeví na loukách, kde se shromažďoval dobytek (nitrifikace kálením).</p>
    <p>V&nbsp;horách je i problém bud a chat. Například k Labské boudě byla přivedena asfaltka skrz rašeliniště, navíc byl tento velkokapacitní hotel vybaven jen septikem, který byl v&nbsp;zimě vypouštěn pod sníh. Louka pod boudou tak byla ruderalizována, navíc se živiny dostávaly až do Labského vodopádu. Dnes je bouda vybavena čističkou, ale i ta má své limity – nepracuje dobře při nízkých teplotách.</p>
    <p>Z&nbsp;naší krajiny taktéž téměř vymizel dříve běžný kalcifobní vlčí bob mnoholistý (lupina), který byl dovezen z&nbsp;USA a který byl schopný pomocí bakterií na svých hlízkách vázat vzdušný dusík. Po jeho odumření tak došlo k&nbsp;mírnému obohacení půdy o dusík, dříve sázeno do lesů (zživnění půd), dnes však i nežádoucí.</p>
    <p>Naopak současná situace velmi svědčí nitrofilním invazivním druhům, obzvláště netýkavce žláznaté a malokvěté. Druhá jmenovaná se u nás obzvláště šíří, dokonce se v&nbsp;našich podmínkách adaptovala, a ačkoliv dříve byla menší, dnes jsou až metrové. Obstojně likviduje původní podrost lesů, zde nemá konkurenta a ani ji nic nespásá.</p>
    <hr />
    <h2>Vliv dusíku na biodiverzitu</h2>
    <p>Z&nbsp;pokusných ploch na podhorských pastvinách u Mariánských Lázní, které byly po 20 let hnojeny různou intenzitou, plyne, že s&nbsp;počtem dodávaného dusíku klesá biodiverzita, tedy počet druhů. Experiment byl prováděn od konce 60. let do let 90.</p>
    <p>Množství trávy je při intenzivním hnojení sice vyšší, což vyhovuje zemědělcům, jenomže se zde prosadí jen 5 až 6 agresivních druhů trav. Květnaté druhy jsou vytlačeny a květnaté louky tak pomalu mizí.</p>
    <p>Ekonomickým argumentem pro záchranu květnatých však může být fakt, že seno z&nbsp;květnaté louky obsahuje i druhy s&nbsp;léčivými účinky a dobytek přijímá zároveň různorodé živiny – dobytek je tak zdravější.</p>
    <p>Příkladem zničení vzácných lučních společenstev mohou být horské louky v&nbsp;Bílých Karpatech. Těsně po revoluci tyto louky s&nbsp;výskytem orchidejí státní statek zahnojil a zničil, tím zabránil vyhlášení chráněné rezervace, protože zde již pak nebylo co chránit.</p>
    <p>Silnice vedoucí k&nbsp;lukám však později byla vyjmuta ze státní sítě, zemědělcům se nevyplatí ji na vlastní náklady udržovat, a tak na těchto lukách přestávají hospodařit. O louky se začala starat správa přírody, ta je sekala a odvážela z&nbsp;nich biomasu. Po 20 letech je již trochu patrný výsledek, byl nastartován proces acidifikace a počet živin klesá.</p>
    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Nedostatek a nadbytek živin v přírodě</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/okyseleni-prostredi/"><button className="inv">&larr; Vývoj okyselení prostředí v ČR</button></Link>
    <Link to="/ekologie-zivotni-prostredi/dalsi-ekologicke-faktory/"><button className="inv">Další významné ekologické faktory &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
